<template>
  <div id="intro">
    <img id="fixed-canvas-container" alt="CarboBrake Wheel"/>
  </div>

</template>

<script>
export default {
  name: "Intro",
  data() {
    return {
      introImages : [
        require("../assets/stepall-hot-fast.gif"),
        require("../assets/home_animation/_STEP3_SCROLL000.png"),
      ],
    };
  },
  methods : {
    brake() {
      document.body.style.overflowY = "hidden";
      this.canvasContainer = document.getElementById("fixed-canvas-container");
      this.canvasContainer.src = this.introImages[0];
      this.setImageMargin();

      setTimeout(() => {
        this.canvasContainer.style.display = "none";
        document.body.style.overflowY = "visible";
        document.getElementById("step0").style.display = "block";
        document.getElementById("scroll-canvas-container").style.display = "block";
      }, 2500);

      this.$cookie.set('animation', 'yes', 7);
    },
    setImageMargin() {
      let animationImg = document.getElementById("fixed-canvas-container");
      let scrollingImg = document.getElementById("scroll-canvas-container");
      if (window.innerWidth < 769) {
        animationImg.style.marginLeft =
            "-" + ((window.innerWidth) / 2) + "px";
        scrollingImg.style.marginLeft =
            "-" + ((window.innerWidth) / 2) + "px";
      }else{
        animationImg.style.marginLeft =
            (window.innerWidth - animationImg.offsetWidth) / 2 + "px";
        scrollingImg.style.marginLeft =
            (window.innerWidth - animationImg.offsetWidth) / 2 + "px";
      }
    },
  },
  mounted() {
    if (this.$cookie.get('animation')!== "yes" && window.innerWidth > 769){
      window.addEventListener('load', this.brake);
    } else{
      this.canvasContainer = document.getElementById("fixed-canvas-container");
      this.canvasContainer.src = this.introImages[1];
      document.getElementById("step0").style.display = "block";
      document.getElementById("scroll-canvas-container").style.display = "block";
      this.setImageMargin();
      setTimeout(() => {
        document.getElementById("fixed-canvas-container").style.display = "none";
      },1000)
    }
  }
};
</script>

<style scoped>
#fixed-canvas-container {
  display: block;
  position: absolute;
  left: 0;
  height: 100%;
}
@media (max-width: 768px) {

  #intro {
    width: 100vh;
    margin-left: -200px;
  }
  #fixed-canvas-container {

    position: absolute;
    left: 0;
    height: 100vh;
    width: 100%;
  }

}
</style>