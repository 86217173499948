<template>

  <div class="home" v-if="!$store.state.loading">

    <intro></intro>
    <homepage-menu></homepage-menu>

    <div id="animation-container">

      <img id="scroll-canvas-container" :src="this.animationImages[0]" alt=""/>

      <div id="step1">
        <h2>{{ $t("calipers-title") }}</h2>
        <h3>{{ $t("calipers-section1-title") }}</h3>
        <p v-html="$t('calipers-home-teaser')"></p>
        <router-link to="/calipers"
        ><button class="learn-more">
          {{ $t("btn-learn-more") }}
        </button></router-link>
      </div>
      <div id="step2">
        <h2>{{ $t("pads-title") }}</h2>
        <h3>{{ $t("pads-section1-title") }}</h3>
        <p v-html="$t('pads-home-teaser')"></p>
        <router-link to="/pads"
        ><button class="learn-more">
          {{ $t("btn-learn-more") }}
        </button></router-link>
      </div>
      <div id="step3">
        <h2>{{ $t("rotors-title") }}</h2>
        <h3>{{ $t("rotors-section1-title") }}</h3>
        <p v-html="$t('rotors-home-teaser')"></p>
        <router-link to="/rotors"
        ><button class="learn-more">
          {{ $t("btn-learn-more") }}
        </button></router-link>
      </div>
    </div>

    <div class="section">
      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
      >
        <h2 v-html="$t('components-home-title')"></h2>
        <h3 v-html="$t('components-home-subtitle')"></h3>
        <p v-html="$t('components-home-teaser')"></p>
      </div>

      <div
          class="section-paragraph side"
          data-aos="fade-left"
          data-aos-duration="1000"
      >
        <img :src="homeImages[1]" alt="CarboBrake components"/>
      </div>
    </div>

    <div class="section">
      <div
          class="section-title side"
          data-aos="fade-right"
          data-aos-duration="1000"
      >
        <img :src="homeImages[2]" alt="CarboBrake Cooling"/>
      </div>

      <div
          class="section-paragraph side"
          data-aos="fade-left"
          data-aos-duration="1000"
      >
        <h2 v-html="$t('cooling-title')"></h2>
        <h3 v-html="$t('cooling-section1-title')"></h3>
        <p v-html="$t('cooling-home-teaser')"></p>
        <router-link to="/cooling"
        ><button class="learn-more">
          {{ $t("btn-learn-more") }}
        </button></router-link
        >
      </div>
    </div>

    <Instagram></Instagram>

    <theta-carousel> </theta-carousel>

  </div>
</template>

<script>
// @ is an alias to /src
import ThetaCarousel from "@/components/ThetaCarousel.vue";
import { TweenMax, Linear, /*Power3*/ } from "gsap";
import Intro from "@/components/Intro";
import Instagram from "@/components/Instagram";
import HomepageMenu from "@/components/HomepageMenu";

export default {
  name: "Home",
  components: {
    Instagram,
    Intro,
    ThetaCarousel,
    HomepageMenu,
  },
  data() {
    return {
      homeImages: [
        require("../assets/stepall-hot-fast.gif"),
        require("../assets/components.jpg"),
        require("../assets/cooling_home.jpg"),
      ],
      animationImages: [
        /*require("../assets/home_animation/_STEP3_SCROLL000.png"),
        require("../assets/home_animation/_STEP3_SCROLL001.png"),
        require("../assets/home_animation/_STEP3_SCROLL002.png"),
        require("../assets/home_animation/_STEP3_SCROLL003.png"),
        require("../assets/home_animation/_STEP3_SCROLL004.png"),
        require("../assets/home_animation/_STEP3_SCROLL005.png"),
        require("../assets/home_animation/_STEP3_SCROLL006.png"),
        require("../assets/home_animation/_STEP3_SCROLL007.png"),
        require("../assets/home_animation/_STEP3_SCROLL008.png"),
        require("../assets/home_animation/_STEP3_SCROLL009.png"),
        require("../assets/home_animation/_STEP3_SCROLL010.png"),
        require("../assets/home_animation/_STEP3_SCROLL011.png"),
        require("../assets/home_animation/_STEP3_SCROLL012.png"),
        require("../assets/home_animation/_STEP3_SCROLL013.png"),
        require("../assets/home_animation/_STEP3_SCROLL014.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL000.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL001.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL002.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL003.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL004.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL005.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL006.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL007.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL008.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL009.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL010.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL011.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL012.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL013.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL014.png"),
        require("../assets/home_animation/_STEP2_5_SCROLL015.png"),
        require("../assets/home_animation/_STEP3_SCROLL015.png"),
        require("../assets/home_animation/_STEP3_SCROLL016.png"),
        require("../assets/home_animation/_STEP3_SCROLL017.png"),*/
        require("../assets/home_animation/_STEP3_SCROLL018.png"),
        require("../assets/home_animation/_STEP3_SCROLL019.png"),
        require("../assets/home_animation/_STEP3_SCROLL020.png"),
        require("../assets/home_animation/_STEP3_SCROLL021.png"),
        require("../assets/home_animation/_STEP3_SCROLL022.png"),
        require("../assets/home_animation/_STEP3_SCROLL023.png"),
        require("../assets/home_animation/_STEP3_SCROLL024.png"),
        require("../assets/home_animation/_STEP3_SCROLL025.png"),
        require("../assets/home_animation/_STEP3_SCROLL026.png"),
        require("../assets/home_animation/_STEP3_SCROLL027.png"),
        require("../assets/home_animation/_STEP3_SCROLL028.png"),
        require("../assets/home_animation/_STEP3_SCROLL029.png"),
        require("../assets/home_animation/_STEP3_SCROLL030.png"),
        require("../assets/home_animation/_STEP3_SCROLL031.png"),
        require("../assets/home_animation/_STEP3_SCROLL032.png"),
        require("../assets/home_animation/_STEP3_SCROLL033.png"),
        require("../assets/home_animation/_STEP3_SCROLL034.png"),
        require("../assets/home_animation/_STEP3_SCROLL035.png"),
        require("../assets/home_animation/_STEP3_SCROLL036.png"),
        require("../assets/home_animation/_STEP3_SCROLL037.png"),
        require("../assets/home_animation/_STEP3_SCROLL038.png"),
        require("../assets/home_animation/_STEP3_SCROLL039.png"),
        require("../assets/home_animation/_STEP3_SCROLL040.png"),
        require("../assets/home_animation/_STEP3_SCROLL041.png"),
        require("../assets/home_animation/_STEP3_SCROLL042.png"),
        require("../assets/home_animation/_STEP3_SCROLL043.png"),
        require("../assets/home_animation/_STEP3_SCROLL044.png"),
        require("../assets/home_animation/_STEP3_SCROLL045.png"),
        require("../assets/home_animation/_STEP3_SCROLL046.png"),
        require("../assets/home_animation/_STEP3_SCROLL047.png"),
        require("../assets/home_animation/_STEP3_SCROLL048.png"),
        require("../assets/home_animation/_STEP3_SCROLL049.png"),
        require("../assets/home_animation/_STEP3_SCROLL050.png"),
        require("../assets/home_animation/_STEP3_SCROLL051.png"),
        require("../assets/home_animation/_STEP3_SCROLL052.png"),
        require("../assets/home_animation/_STEP3_SCROLL053.png"),
        require("../assets/home_animation/_STEP3_SCROLL054.png"),
        require("../assets/home_animation/_STEP3_SCROLL055.png"),
        require("../assets/home_animation/_STEP3_SCROLL056.png"),
        require("../assets/home_animation/_STEP3_SCROLL057.png"),
        require("../assets/home_animation/_STEP3_SCROLL058.png"),
        require("../assets/home_animation/_STEP3_SCROLL059.png"),
        require("../assets/home_animation/_STEP3_SCROLL060.png"),
        require("../assets/home_animation/_STEP3_SCROLL061.png"),
        require("../assets/home_animation/_STEP3_SCROLL062.png"),
        require("../assets/home_animation/_STEP3_SCROLL063.png"),
        require("../assets/home_animation/_STEP3_SCROLL064.png"),
        require("../assets/home_animation/_STEP3_SCROLL065.png"),
        require("../assets/home_animation/_STEP3_SCROLL066.png"),
        require("../assets/home_animation/_STEP3_SCROLL067.png"),
        require("../assets/home_animation/_STEP3_SCROLL068.png"),
        require("../assets/home_animation/_STEP3_SCROLL069.png"),
        require("../assets/home_animation/_STEP3_SCROLL070.png"),
        require("../assets/home_animation/_STEP3_SCROLL071.png"),
        require("../assets/home_animation/_STEP3_SCROLL072.png"),
        require("../assets/home_animation/_STEP3_SCROLL073.png"),
        require("../assets/home_animation/_STEP3_SCROLL074.png"),
        require("../assets/home_animation/_STEP3_SCROLL075.png"),
        require("../assets/home_animation/_STEP3_SCROLL076.png"),
        require("../assets/home_animation/_STEP3_SCROLL077.png"),
        require("../assets/home_animation/_STEP3_SCROLL078.png"),
        require("../assets/home_animation/_STEP3_SCROLL079.png"),
        require("../assets/home_animation/_STEP3_SCROLL080.png"),
        require("../assets/home_animation/_STEP3_SCROLL081.png"),
        require("../assets/home_animation/_STEP3_SCROLL082.png"),
        require("../assets/home_animation/_STEP3_SCROLL083.png"),
        require("../assets/home_animation/_STEP3_SCROLL084.png"),
        require("../assets/home_animation/_STEP3_SCROLL085.png"),
        require("../assets/home_animation/_STEP3_SCROLL086.png"),
        require("../assets/home_animation/_STEP3_SCROLL087.png"),
        require("../assets/home_animation/_STEP3_SCROLL088.png"),
        require("../assets/home_animation/_STEP3_SCROLL089.png"),
        require("../assets/home_animation/_STEP3_SCROLL090.png"),
        require('../assets/home_animation/_STEP3_SCROLL091.png'),
        require('../assets/home_animation/_STEP3_SCROLL092.png'),
        require('../assets/home_animation/_STEP3_SCROLL093.png'),
        require('../assets/home_animation/_STEP3_SCROLL094.png'),
        require('../assets/home_animation/_STEP3_SCROLL095.png'),
        require('../assets/home_animation/_STEP3_SCROLL096.png'),
        require('../assets/home_animation/_STEP3_SCROLL097.png'),
        /*require('../assets/home_animation/_STEP3_SCROLL098.png'),
        require('../assets/home_animation/_STEP3_SCROLL099.png'),
        require('../assets/home_animation/_STEP3_SCROLL100.png'),
        require('../assets/home_animation/_STEP3_SCROLL101.png'),
        require('../assets/home_animation/_STEP3_SCROLL102.png'),
        require('../assets/home_animation/_STEP3_SCROLL103.png'),
        require('../assets/home_animation/_STEP3_SCROLL104.png'),
        require('../assets/home_animation/_STEP3_SCROLL105.png')*/
      ],
      obj: { curImg: 0 },
      imgContainer: "",
      canvasContainer: "",
      step: 0,
      sceneHome: null,
      sceneStep1Appear: null,
      sceneStep1Disappear: null,
      sceneStep2Appear: null,
      sceneStep2Disappear: null,
      sceneStep3Appear: null,
    };
  },
  methods: {
    reportWindowSize() {
      this.setAnimatedContainerHeight();
    },
    setAnimatedContainerHeight() {
      let animationContainer = document.getElementById("animation-container");
      if (window.innerWidth < 769) {
        animationContainer.style.height = window.innerHeight - 60 + "px";
      } else {
        animationContainer.style.height = window.innerHeight + "px";
      }
    },
  },
  async mounted() {

    this.canvasContainer = document.getElementById("fixed-canvas-container");
    document.body.style.overflowY = "visible";

    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch("LOAD_IMAGES", [...this.homeImages, ...this.animationImages])
        .then(() => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$store.commit("setFooterVisible");

              //simulate resize for scrolling transitions
              let event;
              if (typeof Event === "function") {
                event = new Event("resize");
              } else {
                //IE
                event = document.createEvent("Event");
                event.initEvent("resize", true, true);
              }
              window.dispatchEvent(event);
            }, 1100);

            this.setAnimatedContainerHeight();

            window.addEventListener("resize", this.reportWindowSize);

            //SCROLL ANIMATION

            // TweenMax can tween any property of any object. We use this object to cycle through the array
            let obj = { curImg: 0 };

            // create tween to change images on scroll
            let tween = TweenMax.to(obj, 0.5, {
              curImg: this.animationImages.length - 1, // animate propery curImg to number of images
              roundProps: "curImg", // only integers so it can be used as an array index
              repeat: 0, // repeat 3 times
              immediateRender: true, // load first image automatically
              ease: Linear.easeNone, // show every image the same ammount of time
              startAt : {y:1200},
              onUpdate: () => {
                document.getElementById(
                    "scroll-canvas-container"
                ).src = this.animationImages[obj.curImg];
                // set the image source
              },
            });

            ///////

            this.sceneHome = this.$scrollmagic
                .scene({
                  // ID of element where animation starts
                  triggerElement: "#animation-container",

                  // {0,0.5,1} - animations starts from {top,center,end} of window
                  triggerHook: 0,

                  // Duration of animation
                  duration: "5000",
                  offset: 0,
                })

                // Declaration of animation and attaching to element
                .setTween(tween)
                // Helpful tags for orientation on the screen
                //.addIndicators({ name: 'home' })
                .setPin("#animation-container");

            // add scenes to window scrollmagic controller -----> così funziona!!!!
            this.$scrollmagic.addScene(this.sceneHome);

            this.sceneStep1Appear = this.$scrollmagic
                .scene({
                  // ID of element where animation starts
                  triggerElement: "#animation-container",

                  // {0,0.5,1} - animations starts from {top,center,end} of window
                  triggerHook: 0,

                  // Duration of animation
                  duration: 200,
                  offset: 0,
                })

                // Declaration of animation and attaching to element
                .setTween("#step1", {
                  css: {
                    opacity: 1,
                    display: "block", // the tween duration can be omitted and defaults to 1
                  },
                })
            // Helpful tags for orientation on the screen
            //.addIndicators({ name: 'step1 appear' })

            // add scenes to window scrollmagic controller
            this.$scrollmagic.addScene(this.sceneStep1Appear);

            this.sceneStep1Disappear = this.$scrollmagic
                .scene({
                  // ID of element where animation starts
                  triggerElement: "#animation-container",

                  // {0,0.5,1} - animations starts from {top,center,end} of window
                  triggerHook: 0,

                  // Duration of animation
                  duration: 200,
                  offset: 550,
                })

                // Declaration of animation and attaching to element
                .setTween("#step1", {
                  css: {
                    opacity: 0,
                    display: "none", // the tween duration can be omitted and defaults to 1
                  },
                })
            // Helpful tags for orientation on the screen
            //.addIndicators({ name: 'step1  disappear' })

            // add scenes to window scrollmagic controller
            this.$scrollmagic.addScene(this.sceneStep1Disappear);

            this.sceneStep2Appear = this.$scrollmagic
                .scene({
                  // ID of element where animation starts
                  triggerElement: "#animation-container",

                  // {0,0.5,1} - animations starts from {top,center,end} of window
                  triggerHook: 0,

                  // Duration of animation
                  duration: 200,
                  offset: 2200,
                })

                // Declaration of animation and attaching to element
                .setTween("#step2", {
                  css: {
                    opacity: 1,
                    display: "block", // the tween duration can be omitted and defaults to 1
                  },
                })
            // Helpful tags for orientation on the screen
            //.addIndicators({ name: 'step2 appear' })

            // add scenes to window scrollmagic controller
            this.$scrollmagic.addScene(this.sceneStep2Appear);

            this.sceneStep2Disappear = this.$scrollmagic
                .scene({
                  // ID of element where animation starts
                  triggerElement: "#animation-container",

                  // {0,0.5,1} - animations starts from {top,center,end} of window
                  triggerHook: 0,

                  // Duration of animation
                  duration: 200,
                  offset: 3000,
                })

                // Declaration of animation and attaching to element
                .setTween("#step2", {
                  css: {
                    opacity: 0,
                    display: "none", // the tween duration can be omitted and defaults to 1
                  },
                })
            // Helpful tags for orientation on the screen
            //.addIndicators({ name: 'step2  disappear' })

            // add scenes to window scrollmagic controller
            this.$scrollmagic.addScene(this.sceneStep2Disappear);

            this.sceneStep3Appear = this.$scrollmagic
                .scene({
                  // ID of element where animation starts
                  triggerElement: "#animation-container",

                  // {0,0.5,1} - animations starts from {top,center,end} of window
                  triggerHook: 0,

                  // Duration of animation
                  duration: 400,
                  offset: 4400,
                })

                // Declaration of animation and attaching to element
                .setTween("#step3", {
                  css: {
                    opacity: 1,
                    display: "block", // the tween duration can be omitted and defaults to 1
                  },
                })
            // Helpful tags for orientation on the screen
            //.addIndicators({ name: 'step3 appear' })

            // add scenes to window scrollmagic controller
            this.$scrollmagic.addScene(this.sceneStep3Appear);
          });
        });
  },
  beforeDestroy() {
    //reset scroll se l'animazione home non viene scatenata
    document.body.style.overflowY = "visible";

    this.sceneHome = this.sceneHome.destroy();
    this.sceneStep1Disappear = this.sceneStep1Disappear.destroy();
    this.sceneStep3Appear = this.sceneStep3Appear.destroy();
    this.sceneStep2Appear = this.sceneStep2Appear.destroy();
    this.sceneStep2Disappear = this.sceneStep2Disappear.destroy();
  },
};
</script>

<style lang="scss" scoped>


</style>
